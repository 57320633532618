import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MdMenu } from 'react-icons/md'

export default function Example(props) {
  const [open, setOpen] = useState(false)

  return (
    <div className={props.wrapperClass}>
      <button
        type="button"
        className={props.buttonClass + " rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}
        onClick={() => setOpen(true)}
        >
        <span className="sr-only">Open Menu</span>
        <MdMenu className="h-6 w-6" aria-hidden="true" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-[14rem]">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                  >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-sm">
                    <div className="flex h-full flex-col overflow-y-scroll px-6 gap-y-3 bg-white py-10 shadow-xl">
                      <a
                        href="mailto:hello@cyclingguide.org"
                        className="flex items-center text-xl font-medium text-black"
                      >
                        Email Us
                      </a>
                      <a
                        href="/privacy-policy"
                        className="flex items-center text-xl font-medium text-black"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}