import { FaMastodon } from "react-icons/fa";

import CyclingGuideLogo from "../assets/cycling-app-logo.svg";

export default function Footer() {
  return (
    <footer className="w-full py-8 lg:py-14 px-4 lg:px-14 flex flex-col items-center">
      <div className="w-full flex flex-col lg:flex-row items-center justify-center lg:justify-between max-w-6xl">
        <div className="flex flex-col items-center lg:items-start">
          <a href="/" className="flex lg:mb-2">
            <img className="h-14 w-auto" src={CyclingGuideLogo} alt="" />
            <span className="font-semibold text-xl self-center ml-2">
              Cycling Guide
            </span>
          </a>
          <p className="mt-2 font-light text-sm text-black">
            © 2023 Cycling Guide. All Rights Reserved.
          </p>
        </div>
        <div className="flex flex-col items-center lg:items-end">
          <a
            rel="me"
            href="https://mstdn.ca/@CyclingGuide"
            className="mt-2 rounded-full w-min p-1.5 bg-mastodon-purple lg:mb-2"
          >
            <FaMastodon className="h-5 lg:h-7 w-5 lg:w-7" color="#FFFFFF" />
          </a>
          <div className="flex flex-col items-center lg:items-end lg:flex-row gap-x-6">
            <a
              href="/privacy-policy"
              className="mt-2 text-base font-medium text-black"
            >
              Privacy Policy
            </a>
            <p className="mt-2 text-base font-medium text-black">
              <a href="mailto:hello@cyclingguide.org">hello@cyclingguide.org</a> 
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
