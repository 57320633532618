import { TopNav, Footer, ExpansionPanel, DownloadButton, SubscribeDialog } from "../components";
import CyclingMobileImage from "../assets/cycling-app-hero-mobile.svg";
import CyclingExploreRegion from "../assets/cycling-explore-region.svg";
import CyclingOrientedDirections from "../assets/cycling-oriented-directions.svg";
import SuitsConfidenceLevel from "../assets/suits-confidence-level.svg";
import BackgroundBlob from "../assets/background-blob.svg"
import PaperAirplane from "../assets/paper-airplane.svg"

const Landing = () => {

  const infoImagePairs = [
    {
      heading: "Get cycling-oriented directions",
      body: `
        With cycling-centric maps and routes, you’ll have what you need to get you where you want to go without the stress of car traffic or getting lost when cycling.
      `,
      image: CyclingOrientedDirections
    },
    {
      heading: "Suits your cycling confidence level",
      body: `
        See at a glance how much your routes will use cycleways or multi-use paths or quiet streets and choose the route best suited to your needs.
      `,
      image: SuitsConfidenceLevel
    },
    {
      heading: "Explore the region by bicycle",
      body: `
      Discover destinations and learn more about Waterloo Region while riding your bicycle. Try our recreational bike rides to explore scenic rural areas to urban paths and trails.
      `,
      image: CyclingExploreRegion
    },
  ]

  const faqCopy = [
    {
      heading: "How does Cycling Guide create routes for cyclists?",
      body: (<span>Cycling Guide uses a Level of Traffic Stress (LTS) framework and OpenStreetMap data to create routes that prioritize cycling infrastructure or roads with less car traffic.</span>)
    },
    {
      heading: "Do I need to create an account to use Cycling Guide?",
      body: (<span>No, an account is not needed to use the app! You only need to install Cycling Guide on your iPhone or Android phone and give it permission to use your location.</span>)
    },
    {
      heading: "Is the app only available in Waterloo Region?",
      body: (<span>The app is available for download in Canada and the United States. Currently, though, it only provides routes in Waterloo Region and Guelph, ON, Canada. We intend to get it into more cities.</span>)
    },
    {
      heading: "Who are the creators of the Cycling Guide mobile app?",
      body: (<span>
        The Cycling Guide mobile app is developed and maintained by&nbsp;
        <a className="underline cursor-pointer" href="https://cyclingguide.org" target="_blank" rel="noopener noreferrer">Cycling Guide Foundation</a>.</span>)
    }
  ]

  const paperAirplaneStyle = {
    left: "-144px",
    top: "-34px"
  }

  return (
    <div className="flex flex-col items-center text-dark-gray">
      <TopNav />
      <div className="flex lg:grid flex-col px-10 mb-10 grid-cols-[3fr_4fr] grid-rows-[1fr_auto_auto_1fr] max-w-7xl">
        <div className="order-1"/>
        <img
          className="w-full mb-2 row-span-4 order-2"
          src={CyclingMobileImage}
          alt=""
        />
        <div className="font-extrabold leading-tight text-4xl lg:text-6xl text-center lg:text-left order-first lg:order-3">
          Bike routes for everyone with <span className="text-cycling-guide-green">Cycling Guide</span>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 h-min my-4 lg:mt-14 order-4">
          <DownloadButton store={"apple"} />
          <DownloadButton store={"google"} />
        </div>
      </div>
      <div className="bg-off-black py-6 lg:py-14 px-10 w-full flex flex-col items-center">
        <div className="max-w-5xl text-white">
          <p className="font-bold lg:font-semibold text-center text-xl lg:text-4xl mb-2">Best available low-stress, cycling-centric routes</p>
          <p className="text-lg lg:text-center">
            We improve the health of people and the environment by supporting everyday cycling as an easy choice for people to make. 
            Low-stress routes make cycling more enjoyable for everyone.
          </p>
        </div>
      </div>
      <div className="max-w-5xl py-6 flex flex-col gap-y-12 mx-10">
        {infoImagePairs.map((pair, i) => 
          <div key={i} className={`flex flex-col gap-y-2 gap-x-10 lg:items-center lg:justify-between lg:flex-row ${(i % 2 === 0) ? "lg:flex-row-reverse" : ""}`}>
            <div className="lg:w-5/12">
              <p className="font-bold lg:font-semibold text-xl lg:text-4xl mb-2">{pair.heading}</p>
              <p className="text-base">{pair.body}</p>
            </div>
            <img
              className="w-full mt-2 lg:w-2/5"
              src={pair.image}
              alt=""
            />
          </div>  
        )}
      </div>
      <div className="relative overflow-x-hidden overflow-y-hidden w-full flex flex-col items-center py-6 px-10">
        <img
          className="absolute left-blob-small lg:left-blob-large z-0"
          src={BackgroundBlob}
          alt=""
          aria-hidden="true"
        />
        <img
          className="absolute right-blob-small lg:right-blob-large z-0"
          src={BackgroundBlob}
          alt=""
          aria-hidden="true"
        />
        <div className="flex lg:grid flex-col grid-cols-2 grid-rows-[auto_auto_1fr] lg:py-10 relative z-10 max-w-5xl gap-x-10">
          <div className="mb-4">
            <p className="font-bold lg:font-semibold text-xl lg:text-4xl mb-2">Things to know</p>
            <p className="text-base">
            If you're looking for more information about Cycling Guide, 
            we have a few questions and answers that might help. If you 
            have other questions or need help, feel free to contact us.
            </p>
          </div>
          <div className="row-span-3">
            {faqCopy.map((copy, i) => 
              <ExpansionPanel key={i} headerText={copy.heading} bodyText={copy.body} />
            )}
          </div>
          <a
            rel="me"
            href="mailto:hello@cyclingguide.org"
            className="text-center w-full lg:w-fit px-6 py-3 lg:py-2 text-base font-medium rounded-lg drop-shadow-lg text-white bg-black rounded-lg"
            >
            Email us
          </a>
        </div>
      </div>
      <div className="py-6 lg:py-16 px-10 bg-blue-gray w-full text-white flex flex-col items-center z-10 relative">
        <div className="flex flex-col items-center gap-y-4 max-w-5xl relative">
          <p className="text-center font-bold lg:font-semibold text-2xl lg:text-4xl">Want to stay on top of Cycling&nbsp;Guide news?</p>
          <p className="text-center">Stay in touch with updates sent to you via email</p>
          <h3 className="cg-signup"><a href="https://cyclingguide.org/provide-a-better-cycling-experience-with-the-best-available-low-stress-routes/">Sign up now</a></h3>
          <img
            style={paperAirplaneStyle}
            className="hidden lg:block absolute z-0"
            src={PaperAirplane}
            alt=""
            aria-hidden="true"
          />
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Landing;
