const styles = {
  container: {
    margin: "0 auto",
    padding: "2rem 3rem",
    maxWidth: "55rem",
  },
  title: {
    textAlign: "center",
  },
};

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title} className="font-bold text-4xl my-4">
        Privacy Policy
      </h1>
      <p>
        “Personal Information” (PI) is any information about an individual that
        relates to the person's personal characteristics including, but not
        limited to: name, gender, age, home address, phone number, email
        address, and precise location information.
      </p>
      <ol className="list-decimal list-inside mt-2 ml-4">
        <li>
          We will only gather PI when there is a purpose and that purpose is
          disclosed. Generally speaking, we will not collect and store PI
          without ensuring it is anonymized first, for example by converting a
          precise location to a nearby intersection and by stripping other
          identifying information.
        </li>
        <li>
          We will treat PI with care, making it available to a limited number of
          trusted people as it is needed to fulfill their role or where their
          role includes management of such information. All who have access to
          PI will be familiar with this policy and will be briefed on the
          practices they need to employ to ensure the protection of PI.
        </li>
        <li>
          We will take reasonable precautions to store PI securely using trusted
          providers.
        </li>
        <li>
          We will never disclose PI to third parties without explicit
          permission.
        </li>
        <li>We will never sell PI to third parties.</li>
        <li>
          Collection of PI may be for any of the following purposes:
          <ol className="list-decimal list-inside ml-4">
            <li>
              Email address will be used for important communications about the
              application.
            </li>
            <li>
              We will collect information such as imprecise route requests and
              how you use the application, but we will use this information only
              in aggregate to improve the application and the facilities in our
              community.
            </li>
            <li>
              Feedback provided in the application will be stored for later use
              to improve the application. You are responsible to only disclose
              information in the feedback that you wish to share with trusted
              volunteers.
            </li>
          </ol>
        </li>
        <li>
          By providing PI to us, individuals are implying their consent to the
          use of that Personal Information in compliance with this policy.
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
