import {SiGoogleplay, SiApple} from "react-icons/si";

export default function DownloadButton(props) {

  return (
    <a
      href={props.store === "apple" ? "https://apps.apple.com/app/cycling-guide/id1614275966" : "https://play.google.com/store/apps/details?id=com.zeitspace.cyclingapp.cycling_app"}
      type="submit"
      className="flex justify-center w-full lg:w-fit rounded-md bg-black px-6 py-2 lg:py-4 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      <div className="grid grid-rows-[auto_1fr] grid-cols-[auto_1fr] gap-x-2 items-center w-fit">
        <div className='row-span-2 text-xl lg:text-3xl'>
          {props.store === "apple" ? <SiApple className=''/> : <SiGoogleplay/>}
        </div>
        <div className="font-light text-xs leading-3">Available Now</div>
        <div className="font-normal text-lg leading-5">
          {props.store === "apple" ? "App Store" : "Google Play"}
        </div>
      </div>
    </a>
  );
}
