import React, { useState } from 'react';
import {RiArrowUpSLine} from "react-icons/ri";

export default function ExpansionPanel(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="w-full flex flex-row justify-between gap-x-2 mb-4">
      <div className="flex flex-col items-top justify-between cursor-pointer select-none" onClick={() => setExpanded(!expanded)}>
        <span className="text-xl lg:text-lg font-bold lg:font-semibold">{props.headerText}</span>
        {expanded && 
          <p>
            {props.bodyText}
          </p>
        }
      </div>
        <RiArrowUpSLine
          className="text-cycling-guide-green h-10 w-12 shrink-0"
          style={{transform: expanded ? '' : 'rotate(180deg)'}}
        />
    </div>
  );
}
