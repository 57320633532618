import { MobileLinksDrawer } from ".";

import CyclingGuideLogo from "../assets/cycling-app-logo.svg";

export default function TopNav() {
  return (
    <header className="bg-white w-full">
      <nav className="max-w-7xl mx-auto lg:px-8" aria-label="Top">
        <div className="w-full py-4 grid grid-cols-[1fr_auto_1fr] lg:flex items-center justify-center lg:justify-between">
          <MobileLinksDrawer wrapperClass={"lg:hidden ml-4"} />
          <div className="flex items-center px-2">
            <a href="/" className="flex">
              <img className="h-14 w-auto" src={CyclingGuideLogo} alt="" />
              <span className="font-semibold text-xl self-center ml-2">
                Cycling Guide
              </span>
            </a>
          </div>
          <div className="hidden lg:flex ml-10 space-x-12">
            <a
              href="mailto:hello@cyclingguide.org"
              className="flex items-center text-base font-medium text-black"
            >
              Email Us
            </a>
            <a
              href="/privacy-policy"
              className="flex items-center text-base font-medium text-black"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}
