import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Landing from "./pages/Landing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Registration from "./pages/Registration";
import TermsOfService from "./pages/TermsOfService";
import MapDataDisclaimer from "./pages/MapDataDisclaimer";
import SubscriptionComplete from "./pages/SubscriptionComplete";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement)

root.render(
  <React.StrictMode>
    <Helmet>
      <link rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Open+Sans"></link>
    </Helmet>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="registration" element={<Registration />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="map-data-disclaimer" element={<MapDataDisclaimer />} />
        <Route path="subscription-complete" element={<SubscriptionComplete />} />
        <Route
          path="/ride-list-item-detail/:id"
          element={<Navigate to="/" />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
