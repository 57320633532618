const styles = {
  container: {
    margin: "0 auto",
    padding: "2rem 3rem",
    maxWidth: "55rem",
  },
  title: {
    textAlign: "center",
  },
};

const MapDataDisclaimer = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title} className="font-bold text-4xl my-4">
        Map Data Disclaimer
      </h1>
      <p className="pb-4">Last updated March 1, 2024</p>
      <p className="pb-4">
        Cycling Guide uses <strong>OpenStreetMap</strong>, which provides map data for thousands of 
        websites, mobile apps, and hardware devices. OpenStreetMap is built by a 
        community of mappers that contribute and maintain data about roads, trails, 
        cafés, railway stations, and much more, all over the world. OpenStreetMap 
        emphasizes local knowledge. Contributors use aerial imagery, GPS devices, 
        and low-tech field maps to verify that OSM is accurate and up to date.
        Learn more at <strong><a href="https://www.openstreetmap.org/about">OpenStreetMap.org</a></strong>
        

      </p>
      <p className="pb-4">
        The maps in Cycling Guide are based on OpenStreetMap data which is accumulated 
        from many public contributions. <strong>Halifax Regional Municipality</strong> has 
        not supplied any of the data used in the maps and HRM takes no responsibility 
        for any loss, damage or injury a user of the Halifax Cycling App might sustain 
        from the person’s use of this application.
      </p>

    </div>
  );
};

export default MapDataDisclaimer;
