import React, { useState } from "react";
import { TopNav, Footer } from "../components";
import CyclingMainImage from "../assets/cycling-app-main.svg";

const Registration = () => {
  const [finished, setFinished] = useState(false);
  let loaded = false;

  const oniFrameLoad = () => {
    if (loaded) {
      setFinished(true);
      window.scrollTo(0, 0);
    }
    loaded = true;
  };

  return (
    <div className="grid grid-rows-[auto_1fr_auto] min-h-screen w-screen justify-center">
      <TopNav />
      <div className="grid grid-cols-1 lg:grid-cols-[3fr_2fr] max-w-7xl mt-4">
        <div className="flex flex-col">
          {finished ? (
            <div className="w-full px-4 lg:mt-6 md:px-8 lg:px-16">
              <h1 className="text-base font-medium text-2xl">
                Cycling Guide
              </h1>
              <p className="my-2">
                We're excited to be in touch with you soon. Please note that we
                cannot guarantee all applicants will be selected for the Beta
                Program, but we are definitely interested! If you have any
                questions or are interested in Cycling Guide's app, please don't
                hesitate to reach out -{" "}
                <a
                  href="mailto:hello@cyclingguide.org"
                  className="text-blue-600 visited:text-purple-600 underline"
                >
                  hello@cyclingguide.org
                </a>
              </p>
              <div className="mx-6 flex justify-center">
                <a
                  href="/"
                  className="inline-flex justify-center w-8/12 my-4 py-4 text-base font-medium rounded-md shadow-sm text-white bg-black rounded-lg"
                >
                  Return to main page
                </a>
              </div>
            </div>
          ) : (
            <iframe
              onLoad={oniFrameLoad}
              src="https://docs.google.com/forms/d/e/1FAIpQLSc8sAYNFlbEg_d6kQQ_ytjZLVCz217QOwDHDlvHWv2mLrDXHQ/viewform?embedded=true"
              className="w-full h-[124rem] lg:h-[102rem]"
              title="registration-form"
            >
              Loading…
            </iframe>
          )}
        </div>
        <div className="hidden flex-col lg:flex order-2 max-w-1/2 mr-4">
          <img
            className="flex mb-2 max-h-screen"
            src={CyclingMainImage}
            alt=""
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Registration;
