import CyclingGuideLogo from "../assets/cycling-app-logo.svg";

export default function SubscriptionComplete() {
  return (
    <div className="flex flex-col justify-center items-center text-center h-screen px-2">
      <div href="/" className="flex mb-10">
        <img className="h-14 w-auto" src={CyclingGuideLogo} alt="" />
        <span className="font-semibold text-xl self-center ml-2">
          Cycling Guide
        </span>
      </div>
      <h1 className="font-bold text-xl mb-4">Subscription Confirmed</h1>
      <p>Thanks for signing up for Cycling Guide news updates</p>
    </div>
  );
};
