const styles = {
  container: {
    margin: "0 auto",
    padding: "2rem 3rem",
    maxWidth: "55rem",
  },
  title: {
    textAlign: "center",
  },
};

const TermsOfService = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title} className="font-bold text-4xl my-4">
        TERMS OF SERVICE
      </h1>
      <p className="pb-4">Last updated November 2, 2022</p>
      <p className="pb-4">
        Cycling Guide is licensed to You ("End-User") by Cycling Guide Foundation,
        located and registered in Halifax, Nova Scotia, Canada ("Licensor"), for
        use only under the terms of this License Agreement. Cycling Guide Foundation 
        is also the "Developer".
      </p>
      <p className="pb-4">
        By downloading the Licensed Application from Apple's software
        distribution platform ("App Store") and Google's software distribution
        platform ("Play Store"), and any update thereto (as permitted by this
        License Agreement), You indicate that You agree to be bound by all of
        the terms and conditions of this License Agreement and that You accept
        this License Agreement. App Store and Play Store are referred to in this
        License Agreement as “Services.”
      </p>
      <p className="pb-4">
        The parties of this License Agreement acknowledge that the Services are
        not a Party to this License Agreement and are not bound by any
        provisions or obligations with regard to the Licensed Application, such
        as warranty, liability, maintenance and support thereof. Cycling Guide 
        Foundation, not the Services, is solely responsible for the Licensed
        Application and the content thereof.
      </p>
      <p className="pb-4">
        This License Agreement may not provide for usage rules for the Licensed
        Application that are in conflict with the latest{" "}
        <a
          href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          Apple Media Services Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href="https://play.google.com/intl/en_US/about/play-terms/"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          Google Play Terms of Service
        </a>{" "}
        ("Usage Rules"). Cycling Guide Foundation acknowledges that it had the
        opportunity to review the Usage Rules and this License Agreement is not
        conflicting with them.
      </p>
      <p className="pb-4">
        Cycling Guide when downloaded through the Services, is licensed to You
        for use only under the terms of this License Agreement. The Licensor
        reserves all rights not expressly granted to You. Cycling Guide is to be
        used on devices that operate with Apple's operating systems ("iOS" and
        "Mac OS") or Google's operating system ("Android").
      </p>
      <br />
      <p className="pb-4">TABLE OF CONTENTS</p>
      <div>
        <a
          href="#item1"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          1. THE APPLICATION
        </a>
      </div>
      <div>
        <a
          href="#item2"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          2. SCOPE OF LICENSE
        </a>
      </div>
      <div>
        <a
          href="#item3"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          3. TECHNICAL REQUIREMENTS
        </a>
      </div>
      <div>
        <a
          href="#item4"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          4. MAINTENANCE AND SUPPORT
        </a>
      </div>
      <div>
        <a
          href="#item5"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          5. USE OF DATA
        </a>
      </div>
      <div>
        <a
          href="#item6"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          6. USER-GENERATED CONTRIBUTIONS
        </a>
      </div>
      <div>
        <a
          href="#item7"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          7. CONTRIBUTION LICENSE
        </a>
      </div>
      <div>
        <a
          href="#item8"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          8. LIABILITY
        </a>
      </div>
      <div>
        <a
          href="#item9"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          9. WARRANTY
        </a>
      </div>
      <div>
        <a
          href="#item10"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          10. PRODUCT CLAIMS
        </a>
      </div>
      <div>
        <a
          href="#item11"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          11. LEGAL COMPLIANCE
        </a>
      </div>
      <div>
        <a
          href="#item12"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          12. CONTACT INFORMATION
        </a>
      </div>
      <div>
        <a
          href="#item13"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          13. TERMINATION
        </a>
      </div>
      <div>
        <a
          href="#item14"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
        </a>
      </div>
      <div>
        <a
          href="#item15"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          15. INTELLECTUAL PROPERTY RIGHTS
        </a>
      </div>
      <div>
        <a
          href="#item16"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          16. APPLICABLE LAW
        </a>
      </div>
      <div>
        <a
          href="#item17"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          17. MISCELLANEOUS
        </a>
      </div>
      <br />
      <br />
      <p id="item1" className="pb-4">
        1. THE APPLICATION
      </p>
      <p className="pb-4">
        Cycling Guide ("Licensed Application") is a piece of software created to
        facilitate cycling in Waterloo Region — and customized for iOS and
        Android mobile devices ("Devices"). It is used to find routes that match
        the user's needs.
      </p>
      <br />
      <p id="item2" className="pb-4">
        2. SCOPE OF LICENSE
      </p>
      <p className="pb-4">
        2.1 You are given a non-transferable, non-exclusive, non-sublicensable
        license to install and use the Licensed Application on any Devices that
        You (End-User) own or control and as permitted by the Usage Rules, with
        the exception that such Licensed Application may be accessed and used by
        other accounts associated with You (End-User, The Purchaser) via Family
        Sharing or volume purchasing.
      </p>
      <p className="pb-4">
        2.2 This license will also govern any updates of the Licensed
        Application provided by Licensor that replace, repair, and/or supplement
        the first Licensed Application, unless a separate license is provided
        for such update, in which case the terms of that new license will
        govern.
      </p>
      <p className="pb-4">
        2.3 You may not share or make the Licensed Application available to
        third parties (unless to the degree allowed by the Usage Rules, and with
        Cycling Guide Foundation's prior written consent), sell, rent, lend, lease
        or otherwise redistribute the Licensed Application.
      </p>
      <p className="pb-4">
        2.4 Licensor reserves the right to modify the terms and conditions of
        licensing.
      </p>
      <p className="pb-4">
        2.5 Nothing in this license should be interpreted to restrict
        third-party terms. When using the Licensed Application, You must ensure
        that You comply with applicable third-party terms and conditions.
      </p>
      <br />
      <p id="item3" className="pb-4">
        3. TECHNICAL REQUIREMENTS
      </p>
      <p className="pb-4">
        3.1 Licensor attempts to keep the Licensed Application updated so that
        it complies with modified/new versions of the firmware and new hardware.
        You are not granted rights to claim such an update.
      </p>
      <p className="pb-4">
        3.2 You acknowledge that it is Your responsibility to confirm and
        determine that the app end-user device on which You intend to use the
        Licensed Application satisfies the technical specifications mentioned
        above.
      </p>
      <p className="pb-4">
        3.3 Licensor reserves the right to modify the technical specifications
        as it sees appropriate at any time.
      </p>
      <br />
      <p id="item4" className="pb-4">
        4. MAINTENANCE AND SUPPORT
      </p>
      <p className="pb-4">
        4.1 The Licensor is solely responsible for providing any maintenance and
        support services for this Licensed Application. You can reach the
        Licensor at the email address listed in the App Store or Play Store
        Overview for this Licensed Application.
      </p>
      <p className="pb-4">
        4.2 Cycling Guide Foundation and the End-User acknowledge that the Services
        have no obligation whatsoever to furnish any maintenance and support
        services with respect to the Licensed Application.
      </p>
      <br />
      <p id="item5" className="pb-4">
        5. USE OF DATA
      </p>
      <p className="pb-4">
        You acknowledge that Licensor will be able to access and adjust Your
        downloaded Licensed Application content and Your personal information,
        and that Licensor's use of such material and information is subject to
        Your legal agreements with Licensor and Licensor's privacy policy:{" "}
        <a
          href="https://cyclingguide.app/privacy-policy"
          className="text-blue-600 visited:text-purple-600 underline"
        >
          https://cyclingguide.app/privacy-policy
        </a>
        .
      </p>
      <p className="pb-4">
        You acknowledge that the Licensor may periodically collect and use
        technical data and related information about your device, system, and
        application software, and peripherals, offer product support, facilitate
        the software updates, and for purposes of providing other services to
        you (if any) related to the Licensed Application. Licensor may also use
        this information to improve its products or to provide services or
        technologies to you, as long as it is in a form that does not personally
        identify you.
      </p>
      <br />
      <p id="item6" className="pb-4">
        6. USER-GENERATED CONTRIBUTIONS
      </p>
      <p className="pb-4">
        The Licensed Application may invite you to chat, contribute to, or
        participate in blogs, message boards, online forums, and other
        functionality, and may provide you with the opportunity to create,
        submit, post, display, transmit, perform, publish, distribute, or
        broadcast content and materials to us or in the Licensed Application,
        including but not limited to text, writings, video, audio, photographs,
        graphics, comments, suggestions, or personal information or other
        material (collectively, "Contributions"). Contributions may be viewable
        by other users of the Licensed Application and through third-party
        websites or applications. As such, any Contributions you transmit may be
        treated as non-confidential and non-proprietary. When you create or make
        available any Contributions, you thereby represent and warrant that:
      </p>
      <ol className="list-decimal list-inside ml-4">
        <li>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Licensed Application, and other users of the Licensed
          Application to use your Contributions in any manner contemplated by
          the Licensed Application and this License Agreement.
        </li>
        <li>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness or each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Licensed Application and this License Agreement.
        </li>
        <li>Your Contributions are not false, inaccurate, or misleading.</li>
        <li>
          Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </li>
        <li>
          Your Contributions are not obscene, lewd, lascivious, filthy, violent,
          harassing, libellous, slanderous, or otherwise objectionable (as
          determined by us).
        </li>
        <li>
          Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </li>
        <li>
          Your Contributions are not used to harass or threaten (in the legal
          sense of those terms) any other person and to promote violence against
          a specific person or class of people.
        </li>
        <li>
          Your Contributions do not violate any applicable law, regulation, or
          rule.
        </li>
        <li>
          Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </li>
        <li>
          Your Contributions do not violate any applicable law concerning child
          pornography, or otherwise intended to protect the health or well-being
          of minors.
        </li>
        <li>
          Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </li>
        <li>
          Your Contributions do not otherwise violate, or link to material that
          violates any provision of this License Agreement, or any applicable
          law or regulation.
        </li>
      </ol>
      <p className="pt-2">
        Any use of the Licensed Application in violation of the foregoing
        violates this License Agreement and may result in, among other things,
        termination or suspension of your rights to use the Licensed
        Application.
      </p>
      <br />
      <p id="item7" className="pb-4">
        7. CONTRIBUTION LICENSE
      </p>
      <p className="pb-4">
        By posting your Contributions to any part of the Licensed Application or
        making Contributions accessible to the Licensed Application by linking
        your account from the Licensed Application to any of your social
        networking accounts, you automatically grant, and you represent and
        warrant that you have the right to grant, to us an unrestricted,
        unlimited, irrevocable, perpetual, non-exclusive, transferable,
        royalty-free, fully-paid, worldwide right, and license to host, use
        copy, reproduce, disclose, sell, resell, publish, broadcast, retitle,
        archive, store, cache, publicly display, reformat, translate, transmit,
        excerpt (in whole or in part), and distribute such Contributions
        (including, without limitation, your image and voice) for any purpose,
        commercial advertising, or otherwise, and to prepare derivative works
        of, or incorporate in other works, such as Contributions, and grant and
        authorize sublicenses of the foregoing. The use and distribution may
        occur in any media formats and through any media channels.
      </p>
      <p className="pb-4">
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.
      </p>
      <p className="pb-4">
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area in the Licensed Application.
        You are solely responsible for your Contributions to the Licensed
        Application and you expressly agree to exonerate us from any and all
        responsibility and to refrain from any legal action against us regarding
        your Contributions.
      </p>
      <p className="pb-4">
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any Contributions; (2) to recategorize any
        Contributions to place them in more appropriate locations in the
        Licensed Application; and (3) to prescreen or delete any Contributions
        at any time and for any reason, without notice. We have no obligation to
        monitor your Contributions.
      </p>
      <br />
      <p id="item8" className="pb-4">
        8. LIABILITY
      </p>
      <p className="pb-4">
        8.1 Licensor and Developer take no accountability or responsibility for
        any damages caused due to a breach of duties according to Section 2 of
        this License Agreement. To avoid data loss, You are required to make use
        of backup functions of the Licensed Application to the extent allowed by
        applicable third-party terms and conditions of use. You are aware that
        in case of alterations or manipulations of the Licensed Application, You
        will not have access to the Licensed Application.
      </p>
      <p className="pb-4">
        8.2 Licensor and Developer take no accountability and responsibility in
        case of inherent risks of riding a bicycle including the possibility of
        a route generated by or information provided by the Licensed Application 
        leading to circumstances that elevate risk or directly or indirectly lead 
        to death or injury to you or other people accompanying you.
      </p>
      <br />
      <p id="item9" className="pb-4">
        9. WARRANTY
      </p>
      <p className="pb-4">
        9.1 Licensor makes reasonable efforts to ensure the Licensed Application
        is free of spyware, trojan horses, viruses, or any other malware at the
        time of Your download. Licensor warrants that the Licensed Application
        works as described in the user documentation.
      </p>
      <p className="pb-4">
        9.2 The Licensed Application is provided at no cost and is provided AS
        IS with no warranty explicit or implied to be suitable for any purpose.
      </p>
      <br />
      <p id="item10" className="pb-4">
        10. PRODUCT CLAIMS
      </p>
      <p className="pb-4">
        Cycling Guide Foundation and the End-User acknowledge that Cycling Guide 
        Foundation, and not the Services, is responsible for addressing any claims
        of the End-User or any third party relating to the Licensed Application
        or the End-User’s possession and/or use of that Licensed Application,
        including, but not limited to:
      </p>
      <ol type="i" className="mt-2 ml-4">
        <li className="mb-2">(i) product liability claims;</li>
        <li className="mb-2">
          (ii) any claim that the Licensed Application fails to conform to any
          applicable legal or regulatory requirement; and
        </li>
        <li className="mb-2">
          (iii) claims arising under consumer protection, privacy, or similar
          legislation, including in connection with Your Licensed Application’s
          use of the HealthKit and HomeKit.
        </li>
      </ol>
      <br />
      <p id="item11" className="pb-4">
        11. LEGAL COMPLIANCE
      </p>
      <p className="pb-4">
        You represent and warrant that You are not located in a country that is
        subject to a US Government embargo, or that has been designated by the
        US Government as a "terrorist supporting" country; and that You are not
        listed on any US Government list of prohibited or restricted parties.
      </p>
      <br />
      <p id="item12" className="pb-4">
        12. CONTACT INFORMATION
      </p>
      <p className="pb-4">
        For general inquiries, complaints, questions or claims concerning the
        Licensed Application, please contact:
      </p>
      <span>Cycling Guide Foundation</span>
      <br />
      <span>hello@cyclingguide.org</span>
      <br />
      <br />
      <br />
      <p id="item13" className="pb-4">
        13. TERMINATION
      </p>
      <p className="pb-4">
        The license is valid until terminated by Cycling Guide Foundation or by
        You. Your rights under this license will terminate automatically and
        without notice from Cycling Guide Foundation if You fail to adhere to any
        term(s) of this license. Upon License termination, You shall stop all
        use of the Licensed Application, and destroy all copies, full or
        partial, of the Licensed Application.
      </p>
      <br />
      <p id="item14" className="pb-4">
        14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
      </p>
      <p className="pb-4">
        Cycling Guide Foundation represents and warrants that Cycling Guide Foundation
        will comply with applicable third-party terms of agreement when using
        Licensed Application.
      </p>
      <p className="pb-4">
        In accordance with Section 9 of the "Instructions for Minimum Terms of
        Developer's End-User License Agreement," both Apple and Google and their
        subsidiaries shall be third-party beneficiaries of this End User License
        Agreement and — upon Your acceptance of the terms and conditions of this
        License Agreement, both Apple and Google will have the right (and will
        be deemed to have accepted the right) to enforce this End User License
        Agreement against You as a third-party beneficiary thereof.
      </p>
      <br />
      <p id="item15" className="pb-4">
        15. INTELLECTUAL PROPERTY RIGHTS
      </p>
      <p className="pb-4">
        Cycling Guide Foundation and the End-User acknowledge that, in the event of
        any third-party claim that the Licensed Application or the End-User's
        possession and use of that Licensed Application infringes on the third
        party's intellectual property rights, Cycling Guide Foundation, and not the
        Services, will be solely responsible for the investigation, defense,
        settlement, and discharge of any such intellectual property infringement
        claims.
      </p>
      <br />
      <p id="item16" className="pb-4">
        16. APPLICABLE LAW
      </p>
      <p className="pb-4">
        This License Agreement is governed by the laws of Canada excluding its
        conflicts of law rules.
      </p>
      <br />
      <p id="item17" className="pb-4">
        17. MISCELLANEOUS
      </p>
      <p className="pb-4">
        17.1 If any of the terms of this agreement should be or become invalid,
        the validity of the remaining provisions shall not be affected. Invalid
        terms will be replaced by valid ones formulated in a way that will
        achieve the primary purpose.
      </p>
      <p className="pb-4">
        17.2 Collateral agreements, changes and amendments are only valid if
        laid down in writing. The preceding clause can only be waived in
        writing.
      </p>
    </div>
  );
};

export default TermsOfService;
